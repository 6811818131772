import React, { useEffect } from "react";
import NavBar from "../navigationBar/NavBar";
import ImageSlider from "../slider/imageSlider";
import Footer from "../footer/footer";
import { projectImageSlider } from "../data/sliderData";
import ClientListing from "../clientGallery/clientListing";
import { usePageTracking } from "../utils/usePageTracking";

export default function Clients() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBar />
      <ImageSlider slides={projectImageSlider} />
      <ClientListing />
      <Footer />
    </div>
  );
}

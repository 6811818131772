import React, { useState, useEffect } from "react";
import "./dropDown.scss";

export default function DropDownCo(props) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const client_name = urlParams.get("client");

  const { data, onGetDataClient, onGetDataService, id, dropDownChange } = props;
  const [currentSelectedDataClient, setCurrentSelectedDataClient] =
    useState(client_name);
  const [currentSelectedDataService, setCurrentSelectedDataService] =
    useState("all");

  const urlQueryString = (search, data) => {
    if ("URLSearchParams" in window) {
      var searchParams = new URLSearchParams(window.location.search);
      searchParams.set(search, data);
      var newRelativePathQuery =
        window.location.pathname + "?" + searchParams.toString();
      window.history.pushState(null, "", newRelativePathQuery);
    }
  };

  const changeSelected = (newSelected) => {
    if (id === "client_drop") {
      setCurrentSelectedDataClient(newSelected);
      urlQueryString("client", newSelected);
    }
    if (id === "service_drop") {
      setCurrentSelectedDataService(newSelected);
      urlQueryString("service", newSelected);
    }
    let change = true;
    dropDownChange(change);
  };

  useEffect(() => {
    onGetDataClient && onGetDataClient(currentSelectedDataClient);
  }, [currentSelectedDataClient, onGetDataClient]);

  useEffect(() => {
    onGetDataService && onGetDataService(currentSelectedDataService);
  }, [currentSelectedDataService, onGetDataService]);

  const onClickClearFilter = () => {
    if (id === "client_drop") {
      setCurrentSelectedDataClient("");
      window.history.pushState({}, document.title, window.location.pathname);
    }
    if (id === "service_drop") {
      setCurrentSelectedDataService("all");
      urlQueryString("service", "all");
    }
  };

  return (
    <div className="drop__down__cont">
      <div className="drop__down__filter">
        <select
          onChange={(event) => changeSelected(event.target.value)}
          value={
            id === "service_drop"
              ? currentSelectedDataService
              : currentSelectedDataClient
          }
          className="drop__down"
        >
          {data.desc?.map((item) => {
            return (
              <option value={item.title} key={item.id}>
                {item.title}
              </option>
            );
          })}
        </select>
      </div>
      <div className="drop__down__filter__item">
        <button onClick={onClickClearFilter} className="filter__clear__button">
          clear filter
        </button>
      </div>
    </div>
  );
}

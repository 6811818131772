import React from "react";
import { Route, Routes } from "react-router-dom";
import About from "./pages/about";
import Clients from "./pages/clients";
import Home from "./pages/home";
import OurTeam from "./pages/ourTeam";
import ProjectDetailPage from "./pages/projectDetailPage";
import Projects from "./pages/projects";
import Services from "./pages/services";
import ServicesDetails from "./pages/servicesDetails";
import ReactGA from "react-ga4";
import ContactUs from "./pages/contactUs";

export default function Main() {
  ReactGA.initialize("G-833NLVF9YM", {
    legacyDimensionMetric: false,
    gtagOptions: { send_page_view: true },
  });

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about_us" element={<About />} />
        <Route path="/our_team" element={<OurTeam />} />
        <Route path="/our_clients" element={<Clients />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route
          path="/projects/project_details"
          element={<ProjectDetailPage />}
        />
        <Route path="/services/service_details" element={<ServicesDetails />} />
      </Routes>
    </div>
  );
}

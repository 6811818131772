import React, { useEffect } from "react";
import Footer from "../footer/footer";
import NavBar from "../navigationBar/NavBar";
import ImageSlider from "../slider/imageSlider";
import { OurTeamImages } from "../data/sliderData";
import building from "../../assets/image/front.png";
import entrance from "../../assets/image/welcome.png";
import ContactUsForm from "../form/contactUsForm";
import FooterDetails from "../footer/footerDetails/footerDetails";
import "./contactUs.scss";
import { usePageTracking } from "../utils/usePageTracking";

export default function ContactUs() {
  const contactUsImageSlider = [
    {
      id: 1,
      image: building,
    },
    {
      id: 2,
      image: entrance,
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBar />
      <ImageSlider slides={contactUsImageSlider} enableContent={false} />
      <ContactUsForm />
      <div className="contact-us-details-map_cont">
        <div className="contact-us--wrapper">
          <div className="contact-us__details__cont">
            <FooterDetails />
          </div>
          <div className="contact-us__maps__cont">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3862.0237174972744!2d121.06854081535145!3d14.54063608244043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c9b0177c6f05%3A0x65a6da5c034ffdb5!2s8GIG%20Konstruct%2C%20Inc.!5e0!3m2!1sen!2sph!4v1663611973142!5m2!1sen!2sph"
              className="iframe--gmaps"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import "./missionArticle.scss";

export default function ModalArticle(props) {
  const { data, iden } = props;
  return (
    <div
      className={`mission__article__cont${iden === "vision" ? "--vision" : ""}`}
    >
      <div className="mission__article__content__cont">
        <div
          className={`mission__article__header__cont${
            iden === "vision" ? "--vision" : ""
          }`}
        >
          <h3 className="mission__article__header">{data.title}</h3>
        </div>
        <div
          className={`mission__article__content${
            iden === "clients" ? "--clients" : ""
          }`}
        >
          {data.desc.map((item) => {
            return (
              <p key={item.id}>
                {iden === "clients" && <span>&bull;</span>} {item.desc}
              </p>
            );
          })}
        </div>
        {iden === "clients" && (
          <div className="mission__article__button__cont">
            <Link to={data.path}>
              <button className="mission__article__button">See more</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./imageSlider.scss";

const ImageSlider = (props) => {
  const { slides, enableContent, classNameSlider, classNameImage } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = slides?.length;

  const navigate = useNavigate();

  const autoScroll = true;
  let slideInterval;
  let intervalTime = 5000;

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  useEffect(() => {
    if (autoScroll) {
      auto();
    }
    return () => clearInterval(slideInterval);
    //   }, [currentSlide]);
  });

  return (
    slides && (
      <div className="image__slider__container">
        {slides?.map((slide, index) => {
          return (
            <div
              className={
                index === currentSlide
                  ? `slider__image slider__image--current slider__image${classNameSlider}`
                  : "slider__image"
              }
              key={index}
            >
              {index === currentSlide && (
                <div>
                  <img
                    src={slide?.image}
                    alt="8giki slider images"
                    className={`image${classNameImage}`}
                  />
                  {enableContent && (
                    <div className="slider__image__content">
                      <h2>{slide?.heading}</h2>
                      <p>{slide?.desc}</p>
                      <hr />
                      <button
                        className="slider__button"
                        onClick={() => navigate(slide?.path)}
                      >
                        {slide?.cta}
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    )
  );
};

export default ImageSlider;

export const photoGalleryData = {
  id: 1,
  title: "Our Team",
  desc: "A solid project will be found when you have your team around.",
  top_management_level: [
    {
      id: 1,
      image:
        "https://drive.google.com/uc?export=view&id=1P9ZmE8dN-BMS7vkbH26jvRX0Ai7DvvcL",
      name: "Sherill Q. Pelagio",
      position: "Company President",
      alt: "8gigki Company President - Sherill Pelagio",
    },
    {
      id: 2,
      image:
        "https://drive.google.com/uc?export=view&id=1tTsupy2cSjZCtIhRfy7KNmbWPgBYeb1K",
      name: "Vicente C. Pelagio Jr.",
      position: "General Manager",
      alt: "8gigki Company General Manager - Vicente C. Pelagio Jr.",
    },
  ],
  manager_level: [
    {
      id: 1,
      image:
        "https://drive.google.com/uc?export=view&id=1C-U7ZSMSmr5HRrGSeje6M48cYg2vgzia",
      name: "Armando T. Cayanan Jr.",
      position: "Operations Manager",
      alt: "8gigki Company Operations Manager  - Armando T. Cayanan Jr.",
    },
    {
      id: 2,
      image:
        "https://drive.google.com/uc?export=view&id=1g9dCxIthjs8X7VBAI3cF8ECIgW83VZhv",
      name: "Edgardo B. Dacanay Jr.",
      position: "Generator Department Manager",
      alt: "8gigki Company Generator Department Manager  - Edgardo B. Dacanay Jr.",
    },
    {
      id: 3,
      image:
        "https://drive.google.com/uc?export=view&id=1PwblIE0H9NRx-5pFCHXKeR6E4fxNN5M3",
      name: "Christian V. Belda",
      position: "Operation Supervisor",
      alt: "8gigki Company Operation Supervisor  - Christian V. Belda",
    },
    {
      id: 4,
      image:
        "https://drive.google.com/uc?export=view&id=1L-XcMCb-Ko3w8AEbeS6Sl13JM0S94PsY",
      name: "Jonjon R. Hernandez",
      position: "Generator Specialist",
      alt: "8gigki Company Generator Specialist  - Jonjon R. Hernandez",
    },
  ],
  department_level: [],
  employee: [],
};

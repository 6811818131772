import acuPhoto from "../../assets/image/aircon-tools.jpeg";
import acuPhoto1 from "../../assets/image/aircon-tools1.jpeg";
import acu_services_8gigki_icon from "../../assets/image/aircon-tools2.jpeg";
import generatorPhoto from "../../assets/image/generator.jpeg";
import genset_services_8gigki_icon from "../../assets/image/generator1.jpeg";
import constructionPhoto from "../../assets/image/construction1.jpeg";
import construction_services_icon_8gigki from "../../assets/image/construction2.jpeg";
import acuPhoto3 from "../../assets/image/aircon.jpeg";
import servicesPhoto from "../../assets/image/hardhatblue.jpeg";
import planningPhoto from "../../assets/image/planningpapers.jpg";
import ourTeamPhoto from "../../assets/image/planning.jpeg";
import projectsPhoto from "../../assets/image/projects.jpeg";

const companyServicesData = {
  id: 1,
  title: "Services",
  desc: [
    {
      id: 0,
      title: "all",
      details: "",
      path: "",
    },
    {
      id: 1,
      title: "Construction",
      details: [
        {
          id: 1,
          desc: "Plan and Layout",
        },
        {
          id: 2,
          desc: "Civil Works",
        },
        {
          id: 3,
          desc: "Electrical Works",
        },
        {
          id: 4,
          desc: "Mechanical Works",
        },
        {
          id: 5,
          desc: "Voice and Data",
        },
        {
          id: 6,
          desc: "Welding Works",
        },
        {
          id: 7,
          desc: "Carpentry Works",
        },
        {
          id: 8,
          desc: "Excavation, Backfilling, & Concreting",
        },
        {
          id: 9,
          desc: "Painting Works",
        },
        {
          id: 10,
          desc: "Glass and Aluminum",
        },
        {
          id: 11,
          desc: "Supply of Materials and Furnitures",
        },
        {
          id: 12,
          desc: "Rehabilitation / Renovation / Fit-Out",
        },
      ],
      path: "/services/service_details?service_type=Construction",
      image: construction_services_icon_8gigki,
      alt: "8gigki Construction Services Icon",
    },
    {
      id: 2,
      title: "Generator Set",
      details: [
        {
          id: 1,
          desc: "Preventive Maintenance Services for Generator Set(GENSET) (Change Oil, Oil Filter, Fuel Filter, Air Filter, Fuel/Water Separator)",
        },
        {
          id: 2,
          desc: "Services Inspection for all types of GENSET",
        },
        {
          id: 3,
          desc: "Checking, Cleaning and Overhauling of engine",
        },
        {
          id: 4,
          desc: "Supply and Installation of Data KOM and/or Deep Sea Controller",
        },
        {
          id: 5,
          desc: "Service for Actual Load Testing",
        },
        {
          id: 6,
          desc: "Supply and Installation of Auto Crank Controller including Wiring Harness",
        },
        {
          id: 7,
          desc: "Supply and Installation of GENSET Breaker",
        },
        {
          id: 8,
          desc: "Supply and Installation of AVR (Automatic Voltage Regulator)",
        },
        {
          id: 9,
          desc: "Rehabilitation of Wiring Harness",
        },
        {
          id: 10,
          desc: "Radiator Overhauling",
        },
        {
          id: 11,
          desc: "Supply and Installation of Battery and Battery Trickle Charger)",
        },
      ],
      path: "/services/service_details?service_type=Generator Set",
      image: genset_services_8gigki_icon,
      alt: "8gigki Generator Supply and Installation Services Icon",
    },
    {
      id: 3,
      title: "Air Conditioning Unit",
      details: [
        {
          id: 1,
          desc: "Preventive Maintenance Services of All Types of Air Conditioning Unit",
        },
        {
          id: 2,
          desc: "Supply of Air Conditioning Unit",
        },
        {
          id: 3,
          desc: "Installation of Air Conditioning Unit",
        },
        {
          id: 4,
          desc: "Repairs of Air Conditioning Unit",
        },
      ],
      path: "/services/service_details?service_type=Air Conditioning Unit",
      image: acu_services_8gigki_icon,
      alt: "8gigki Supply and Installation Air Conditioning Unit Services Icon",
    },
  ],
};

export { companyServicesData };

import React from "react";
import {
  footerAddressDetails,
  mobileDetails,
  emailDetails,
} from "../../data/footerData";
import MappingUtil from "../../utils/mappingUtil";

export default function FooterDetails() {
  const publicPath = window.location.origin;
  return (
    <div className="footer__det__cont">
      <div className="footer__det__items">
        <div className="footer__det__address">
          <span className="footer__det__icon__cont">
            <img
              src={`${publicPath}/mapPin.png`}
              alt="map pin icon 8gigki find"
              loading="lazy"
            />
          </span>
          <MappingUtil data={footerAddressDetails} tag="li" />
        </div>
        <div className="footer__det__mobile">
          <span className="footer__det__icon__cont">
            <img
              src={`${publicPath}/phone.png`}
              alt="phone icon 8gigki call"
              loading="lazy"
            />
          </span>
          <MappingUtil data={mobileDetails} tag="li" />
        </div>
        <div className="footer__det__email">
          <span className="footer__det__icon__cont">
            <img
              src={`${publicPath}/mail.png`}
              alt="8gigki email address icon"
              loading="lazy"
            />
          </span>
          <MappingUtil data={emailDetails} tag="li" />
        </div>
      </div>
    </div>
  );
}

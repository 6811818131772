import React, { useEffect } from "react";
import Footer from "../footer/footer";
import NavBar from "../navigationBar/NavBar";
import ProjectDetail from "../projectDetails/projectDetail";
import { usePageTracking } from "../utils/usePageTracking";

export default function ProjectDetailPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBar />
      <ProjectDetail />
      <Footer />
    </div>
  );
}

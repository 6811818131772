import React from "react";
import "./photoGallery.scss";
import { photoGalleryData } from "../data/photoGalleryData";
import "../article/missionArticle.scss";

export default function PhotoGallery() {
  const publicPath = window.location.origin;
  const default_photo = `${publicPath}/default_photo.png`;

  return (
    <div className="photo__gallery__cont">
      <div className="photo__gallery__content__cont">
        <div className="photo__gallery__header">
          <h3 className="mission__article__header">{photoGalleryData.title}</h3>
        </div>
        <div className="photo__gallery__desc">
          <p>{photoGalleryData.desc}</p>
        </div>
        <div className="photo__gallery__content">
          <div className="photo__gallery__section">
            {photoGalleryData.top_management_level?.map((item) => {
              return (
                <div key={item.id} className="photo__gallery__item">
                  <img
                    src={item.image ? item.image : default_photo}
                    alt={item.alt}
                    className="gallery__profile__photo"
                    loading="lazy"
                  />
                  <span className="gallery__profile__name">{item.name}</span>
                  <span className="gallery__profile__position">
                    {item.position}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="photo__gallery__section">
            {photoGalleryData.manager_level?.map((item) => {
              return (
                <div key={item.id} className="photo__gallery__item">
                  <img
                    src={item.image ? item.image : default_photo}
                    alt={item.alt}
                    className="gallery__profile__photo"
                    loading="lazy"
                  />
                  <span className="gallery__profile__name">{item.name}</span>
                  <span className="gallery__profile__position">
                    {item.position}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="photo__gallery__section">
            {photoGalleryData.department_level?.map((item) => {
              return (
                <div key={item.id} className="photo__gallery__item">
                  <img
                    src={item.image ? item.image : default_photo}
                    alt={item.alt}
                    className="gallery__profile__photo"
                    loading="lazy"
                  />
                  <span className="gallery__profile__name">{item.name}</span>
                  <span className="gallery__profile__position">
                    {item.position}
                  </span>
                </div>
              );
            })}
          </div>
          <div className="photo__gallery__section">
            {photoGalleryData.employee?.map((item) => {
              return (
                <div key={item.id} className="photo__gallery__item">
                  <img
                    src={item.image ? item.image : default_photo}
                    alt={item.alt}
                    className="gallery__profile__photo"
                    loading="lazy"
                  />
                  <span className="gallery__profile__name">{item.name}</span>
                  <span className="gallery__profile__position">
                    {item.position}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

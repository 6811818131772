import React, { useState, useEffect, useRef } from "react";
import DropDownCo from "../dropDown/dropDownCo";
import "./projectsGallery.scss";
import ResultsList from "../results/resultsList";

import { clientsFullLists } from "../data/clientFullList";
import { companyServicesData } from "../data/servicesFullList";

export default function ProjectsGallery() {
  const [resultsClient, setResultsClient] = useState();
  const [resultsService, setResultsService] = useState();
  const [page, setPage] = useState(1);

  const onGetDataClient = (dataResult) => {
    setResultsClient(dataResult);
  };

  const onGetDataService = (dataResult) => {
    setResultsService(dataResult);
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // console.log(dropDownChange());
    }
  });

  const dropDownChange = (data) => {
    if (data) {
      setPage(1);
    }
  };

  return (
    <div className="projects-gallery__cont">
      <div className="filter__header__cont">
        <div className="filter__items">
          <div className="filter__item">
            <span>Client name:</span>
            <DropDownCo
              id="client_drop"
              data={clientsFullLists}
              onGetDataClient={onGetDataClient}
              passValueClient={resultsClient}
              dropDownChange={dropDownChange}
            />
          </div>
          <div className="filter__item">
            <span>Service Type:</span>
            <DropDownCo
              id="service_drop"
              data={companyServicesData}
              onGetDataService={onGetDataService}
              passValueService={resultsService}
              dropDownChange={dropDownChange}
            />
          </div>
        </div>
      </div>
      <div className="projects-gallery__results__cont">
        <ResultsList
          dataClient={resultsClient}
          dataService={resultsService}
          page={page}
          dropDownChange={dropDownChange}
        />
      </div>
    </div>
  );
}

import acuPhoto from "../../assets/image/aircon-tools.jpeg";
import acuPhoto1 from "../../assets/image/aircon-tools1.jpeg";
import acuPhoto2 from "../../assets/image/aircon-tools2.jpeg";
import generatorPhoto from "../../assets/image/generator.jpeg";
import generatorPhoto1 from "../../assets/image/generator1.jpeg";
import constructionPhoto from "../../assets/image/construction1.jpeg";
import constructionPhoto2 from "../../assets/image/construction2.jpeg";
import acuPhoto3 from "../../assets/image/aircon.jpeg";
import servicesPhoto from "../../assets/image/hardhatblue.jpeg";
import planningPhoto from "../../assets/image/planningpapers.jpg";
import ourTeamPhoto from "../../assets/image/planning.jpeg";
import projectsPhoto from "../../assets/image/projects.jpeg";
import teamPhoto1 from "../../assets/image/teambldg.jpg";
import teamPhoto2 from "../../assets/image/teambldg1.jpg";
import teamPhoto3 from "../../assets/image/teambldg2.jpg";

import olandes_photo1 from "../../assets/image/projects/olandes_photo1.png";
import project2 from "../../assets/image/projects/palaw_office_photo1.png";
import project3 from "../../assets/image/projects/smart_digital_store_photo1.png";

import AMA_Dagupan_photo1 from "../../assets/image/projects/ama_dagupan_photo1.png";
import MMC_photo1 from "../../assets/image/projects/mmc_photo1.png";
import un from "../../assets/image/projects/Untitled-1.png";

const HomeSliderImages = [
  {
    id: 1,
    image: servicesPhoto,
    heading: "About us",
    desc: "Learn more about 8GIG Konstruction Inc.",
    cta: "Click here",
    path: "/about_us",
  },

  {
    id: 2,
    image: planningPhoto,
    heading: "Our Services",
    desc: "Learn more about our Services",
    cta: "Click here",
    path: "/services",
  },
  {
    id: 3,
    image: ourTeamPhoto,
    heading: "Our Team",
    desc: "Learn more about 8GIG Konstruct Key Personnels",
    cta: "Read more",
    path: "/our_team",
  },
];

const OurTeamImages = [
  {
    id: 1,
    image: teamPhoto1,
  },
  {
    id: 2,
    image: teamPhoto2,
  },
  {
    id: 3,
    image: teamPhoto3,
  },
];

const serviceSliderImages = [
  {
    id: 1,
    image: constructionPhoto,
  },

  {
    id: 2,
    image: generatorPhoto,
  },
  {
    id: 3,
    image: acuPhoto2,
  },
  {
    id: 4,
    image: generatorPhoto1,
  },
  {
    id: 5,
    image: constructionPhoto2,
  },
  {
    id: 6,
    image: acuPhoto3,
  },
];

const projectImageSlider = [
  {
    id: 1,
    image: olandes_photo1,
  },
  {
    id: 2,
    image: project2,
  },
  {
    id: 3,
    image: project3,
  },
];

const constructionProject = [
  {
    id: 1,
    image: AMA_Dagupan_photo1,
  },
  {
    id: 2,
    image: MMC_photo1,
  },
  {
    id: 3,
    image: olandes_photo1,
  },
];

const generatorProject = [
  {
    id: 1,
    image:
    un,
  },
];

const acuProject = [
  {
    id: 1,
    image: "",
  },
];

const aboutSliderImages = [
  {
    id: 1,
    image: projectsPhoto,
    heading: "Projects",
    desc: "See 8GIG Konstruct Projects",
    cta: "Click here",
    path: "/projects",
  },

  {
    id: 2,
    image: planningPhoto,
    heading: "Our Services",
    desc: "Learn more about our Services",
    cta: "Click here",
    path: "/services",
  },
  {
    id: 3,
    image: ourTeamPhoto,
    heading: "Our Team",
    desc: "Learn more about 8GIG Konstruct Key Personnels",
    cta: "Read more",
    path: "/our_team",
  },
];
export {
  HomeSliderImages,
  OurTeamImages,
  acuProject,
  constructionProject,
  generatorProject,
  serviceSliderImages,
  projectImageSlider,
  aboutSliderImages,
};

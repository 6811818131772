import React from "react";
import { useNavigate } from "react-router";

export default function HomeGetQuote() {
  const navigate = useNavigate();
  return (
    <div className="home__quote__cont">
      <div className="home__quote__cta__cont">
        <button
          className="home__quote__cta"
          onClick={() => navigate("/contact_us")}
        >
          Contact us
        </button>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";

export default function InputLabelForm(props) {
  const {
    onChange,
    id,
    value,
    label,
    inputClassName,
    placeholder,
    required,
    type,
    element,
    name,
    formErrors,
  } = props;

  const getErrorMessage = (name) => {
    return (
      <small className="contact-us__inputLabel__error__cont">
        {formErrors[name]}
      </small>
    );
  };

  return (
    <div className={`contact-us__inputLabel__cont${inputClassName}`}>
      <label className="contact-us__inputLabel__label">{label}</label>
      {element === "textarea" ? (
        <>
          <textarea
            name={name}
            type={type ?? "text"}
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`contact-us__input-text${inputClassName} ${
              !formErrors[name] ? "" : "contact-us__input-text--error"
            } `}
            // required={required}
          />
          <div>{getErrorMessage(name)}</div>
        </>
      ) : (
        <>
          <input
            name={name}
            type={type ?? "text"}
            id={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`contact-us__input-text${inputClassName} ${
              !formErrors[name] ? "" : "contact-us__input-text--error"
            } `}
            // required={required}
          />
          <div>{getErrorMessage(name)}</div>
        </>
      )}
    </div>
  );
}

import React, { useEffect } from "react";
import NavBar from "../navigationBar/NavBar";
import ImageSlider from "../slider/imageSlider";
import { aboutSliderImages } from "../data/sliderData";
import Footer from "../footer/footer";
import "./about.scss";
import ModalArticle from "../article/modalArticle";

import { missionData } from "../data/companyOverviewData";
import { visionData } from "../data/companyOverviewData";
import { clientsData } from "../data/companyOverviewData";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="about__page__cont">
        <ImageSlider slides={aboutSliderImages} />
        <div className="about_page__article__cont">
          <div className="about_page__mission__cont">
            <ModalArticle data={missionData} />
          </div>
          <div className="about_page__vision__cont">
            <ModalArticle data={visionData} iden="vision" />
          </div>
          <div className="about_page__clients__cont">
            <ModalArticle data={clientsData} iden="clients" />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

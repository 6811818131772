import React from "react";
import NavBar from "../navigationBar/NavBar";
import ImageSlider from "../slider/imageSlider";
import {
  acuProject,
  constructionProject,
  generatorProject,
} from "../data/sliderData";
import Footer from "../footer/footer";
import ServiceDetails from "../details/serviceDetails";
import "./servicesDetails.scss";
import { usePageTracking } from "../utils/usePageTracking";

export default function ServicesDetails() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const service_type = urlParams.get("service_type");

  const getDataImage = () => {
    switch (service_type) {
      case "Construction":
        return constructionProject;
        break;
      case "Generator Set":
        return generatorProject;
        break;
      case "Air Conditioning Unit":
        return acuProject;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <NavBar />
      <div className="services__details__cont">
        <div className="services__details__image-slider__cont">
          <ImageSlider slides={getDataImage()} classNameSlider={'--top'} />
        </div>
        <ServiceDetails />
        <Footer />
      </div>
    </>
  );
}

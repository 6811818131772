import React, { useState } from "react";
import { navbarMenuList } from "../data/navbarMainMenuList.js";
import { Link } from "react-router-dom";
import logo_8gigki from "../../assets/image/logo8gig.png";
import "./navbar.scss";
import NavBarAboutUs from "./NavBarAboutUs.js";
import NavbarMobile from "./mobileNavbar/navbarMobile.js";

export default function NavBar() {
  const [aboutDropDown, setAboutDropDown] = useState(false);
  const [isMobileMode, setIsMobileMode] = useState();

  const aboutOnOver = () => {
    setAboutDropDown(true);
  };

  const aboutOnLeave = () => {
    setAboutDropDown(false);
  };

  return (
    <>
      <NavbarMobile />
      <div className="navbar__cont">
        <Link to={`/`} className="navbar__logo__cont">
          <img
            src={logo_8gigki}
            alt="8giki company logo"
            loading="lazy"
            title="8gigki home page"
          />
        </Link>
        <div className="navbar__menu__cont">
          <ul className="navbar__menu__items">
            {navbarMenuList.map((item) => {
              if (item.title === "About us") {
                return (
                  <li
                    key={item.id}
                    onMouseEnter={aboutOnOver}
                    onMouseLeave={aboutOnLeave}
                  >
                    <Link
                      to={item.path}
                      className={"navbar__link--about"}
                      key={item.id}
                      title={item.tooltip}
                    >
                      {item.title.toUpperCase()}
                      {aboutDropDown && <NavBarAboutUs />}
                    </Link>
                  </li>
                );
              }
              return (
                <li key={item.id}>
                  <Link
                    to={item.path}
                    className={"navbar__link"}
                    title={item.tooltip}
                  >
                    {item.title.toUpperCase()}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { navbarMobileMenuList } from "../../data/navbarMainMenuList";
import logo_8gigki from "../../../assets/image/logo8gig.png";
import "./navbarMobile.scss";

export default function NavbarMobile() {
  const [isActive, setIsActive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const onClickBurger = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    });
  }, []);

  return (
    <>
      <div className="nav__mobile__cont">
        <div
          className={`nav__burger__cont${
            isActive ? "--active" : ""
          } nav__burger__cont${isScrolled ? "--scrolled" : ""}`}
          onClick={onClickBurger}
        >
          <span
            className={`nav__burger__line--line1${
              isActive ? "--active" : ""
            } nav__burger__line${isScrolled ? "--scrolled" : ""}`}
          />
          <span
            className={`nav__burger__line--line2${
              isActive ? "--active" : ""
            } nav__burger__line${isScrolled ? "--scrolled" : ""}`}
          />
          <span
            className={`nav__burger__line--line3${
              isActive ? "--active" : ""
            } nav__burger__line${isScrolled ? "--scrolled" : ""}`}
          />
        </div>
      </div>

      <div className={`nav__mobile__menu${isActive ? "--active" : ""}`}>
        {isActive && (
          <ul className="nav__mobile__links">
            <Link to={`/`} className="nav__mobile__logo__cont">
              <img src={logo_8gigki} alt="8gig konstruct inc logo" />
            </Link>
            {navbarMobileMenuList.map((item) => {
              return (
                <Link
                  to={item.path}
                  key={item.id}
                  className={"nav__mobile__link"}
                >
                  <li>{item.title.toUpperCase()}</li>
                </Link>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
}

import React from "react";
import { navbarAboutList } from "../data/navbarMainMenuList";
import { Link } from "react-router-dom";
import "./navbarAbout.scss";

export default function NavBarAboutUs(props) {
  const { onMouseLeave, onMouseEnter } = props;
  return (
    <div
      className="navbar__about__cont"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <ul className="navbar__about__items">
        {navbarAboutList.map((item) => {
          return (
            <Link to={item.path} className="navbar__about__link" key={item.id}>
              <li>{item.title}</li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { clientsFullLists } from "../data/clientFullList";
import "./clientListing.scss";

export default function ClientListing() {
  const getClientsLeft = () => {
    return clientsFullLists.desc
      .slice(1, clientsFullLists.desc.length / 2 + 4)
      .map((item) => {
        if (item.title) {
          return (
            <Link
              to={`/projects?client=${item.title}`}
              className="client__listing"
              key={item.id}
            >
              <span>&bull;</span>
              <p>{item.title}</p>
            </Link>
          );
        }
      });
  };

  const getClientsRight = () => {
    return clientsFullLists.desc
      .slice(clientsFullLists.desc.length / 2 + 4, clientsFullLists.desc.length)
      .map((item) => {
        if (item.title) {
          return (
            <Link
              to={`/projects?client=${item.title}`}
              className="client__listing"
              key={item.id}
            >
              <span>&bull;</span>
              <p>{item.title}</p>
            </Link>
          );
        }
      });
  };

  return (
    <div className="client__listing__cont">
      <div className="client__listing__content__cont">
        <div className="client__listing__header__cont">
          <h3>Our Clients</h3>
        </div>
        <div className="client__listing__client_cont">
          <div className="client__listing__left">{getClientsLeft()}</div>
          <div className="client__listing__right">{getClientsRight()}</div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { companyServicesData } from "../data/servicesFullList";
import "./galleryService.scss";

export default function GalleryService() {
  return (
    <div className="gallery__service__cont">
      <div className="gallery__service__header__cont">
        <h1>Services</h1>
      </div>
      <div className="gallery__service__services__cont">
        {companyServicesData.desc.map((service) => {
          if (service?.title !== "all") {
            return (
              <Link
                to={service.path}
                key={service.id}
                className="gallery__service__link"
              >
                <div className="gallery__service__services">
                  <img
                    src={service.image}
                    alt={service.alt}
                    className="gallery__service__image__cont"
                    loading="lazy"
                  />
                  <p className="gallery__service__title">
                    {service?.title.toUpperCase()}
                  </p>
                </div>
              </Link>
            );
          }
        })}
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { clientsFullLists } from "../data/clientFullList";
import ImageSlider from "../slider/imageSlider";
import { usePageTracking } from "../utils/usePageTracking";
import "./projectDetail.scss";

export default function ProjectDetail() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const project = urlParams.get("project");
  const project_id = urlParams.get("project_id");
  const client = urlParams.get("client");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getProjects = () => {
    const clients = clientsFullLists?.desc?.filter(function (item) {
      return item.title === client;
    });

    const clients_project = clients.map((item) => {
      return item.projects.filter(function (projects) {
        if (project_id == projects.id && projects.project_title === project) {
          return projects;
        }
      });
    });

    const project_details = clients_project[0][0];

    const {
      id,
      project_amount,
      project_desc,
      project_image,
      project_service_title,
      project_service_type,
      project_title,
    } = project_details;

    return (
      project_details && (
        <div className="project__detail__cont">
          <div className="project__detail__header__cont">
            <h1>{project_title}</h1>
          </div>
          <div className="project__detail__slider__image">
            <ImageSlider slides={project_image} classNameSlider={'--top'}/>
          </div>
          <div className="project__detail__desc__cont">
            <div className="project__detail__desc__header__cont">
              <h3>Project Description</h3>
            </div>
            <div className="project__detail__desc">
              <p>
                Client: <span>{client}</span>
              </p>
              <p>
                Project Title: <span>{project_title}</span>
              </p>
              <p>
                Scope of Work:{" "}
                <span>
                  {project_service_title} - {project_service_type}
                </span>
              </p>
              <p>{project_desc}</p>
              <p>
                Project Amount: <span>{project_amount}</span>
              </p>
            </div>
          </div>
        </div>
      )
    );
  };

  return <div>{getProjects()}</div>;
}

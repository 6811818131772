import React from "react";
import { companyServicesData } from "../data/companyOverviewData";
import { useNavigate } from "react-router-dom";

export default function HomeServices() {
  const navigate = useNavigate();
  return (
    <div className="home__services__cat">
      <div className="home__services__header__cont">
        <h1>Our Services</h1>
      </div>
      <div className="home__services__content__cont">
        {companyServicesData.services.map((item) => {
          return (
            <div key={item.id} className="home__services__cont">
              <h4 className="home__services__content__header">
                {item.service_title}
              </h4>
              <div className="home__services__details__cont">
                {item.details.map((detail) => {
                  return (
                    <div className="home__service__details" key={detail.id}>
                      <span>&bull;</span>
                      <p className="home__services__details__detail__cont">
                        {detail.desc}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      <div className="home__services__cta">
        <button
          onClick={() => navigate("/services")}
          className="home__services__button"
        >
          Read more
        </button>
      </div>
    </div>
  );
}

import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import * as LottiePlayer from "@lottiefiles/lottie-player";
import InputLabelForm from "./formUi/inputLabelForm";
import "./contactUsForm.scss";
import Modal from "../modal/modal";

export default function ContactUsForm() {
  const [formData, setFormData] = useState({
    fullName: "",
    eMail: "",
    contactNumber: "",
    subject: "",
    messageInquiry: "",
  });
  const [formErrors, setFormErrors] = useState({ "": "" });
  const [sendingStatus, setSendingStatus] = useState();
  const [trySent, setTrySent] = useState();
  const [errorStatus, setErrorStatus] = useState();
  const [messageSent, setMessageSent] = useState();

  const contactUsForm = useRef();

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = (values) => {
    const { fullName, contactNumber, eMail, subject, messageInquiry } = values;
    const errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexNumber = /^[0-9]+$/;

    // full name
    if (fullName) {
      if (fullName.length <= 2) {
        errors.fullName = "Please enter a valid Name";
      }
    } else {
      errors.fullName = "Please input your Full Name";
    }
    // contact number
    if (contactNumber) {
      if (
        contactNumber.length <= 7 ||
        contactNumber.length > 11 ||
        !regexNumber.test(contactNumber)
      ) {
        errors.contactNumber = "Please input valid contact number";
      }
    } else {
      errors.contactNumber = "Contact Number is required";
    }
    // email
    if (eMail) {
      if (!regexEmail.test(eMail)) {
        errors.eMail = "Please input valid E-mail Address";
      }
    } else {
      errors.eMail = "Please input your E-mail Address";
    }
    // subject
    if (subject) {
      if (subject.length <= 3) {
        errors.subject = "Please input a valid Subject for your Inquiry";
      }
    } else {
      errors.subject = "Please input Subject for your Inquiry";
    }
    // message
    if (messageInquiry) {
      if (messageInquiry.length <= 3) {
        errors.messageInquiry = "Please enter a valid Inquiry";
      } else if (messageInquiry.split(" ").length < 3) {
        errors.messageInquiry =
          "Inquiry must be composed of more than three (3) words";
      }
    } else {
      errors.messageInquiry = "Please enter your Inquiry";
    }

    return errors;
  };

  useEffect(() => {
    setFormErrors(validate(formData));
  }, [formData]);

  const submitContactForm = (e) => {
    e.preventDefault();
    // setFormErrors(validate(formData));
    const { fullName, eMail, messageInquiry, subject, contactNumber } =
      formData;

    if (formErrors && Object.keys(formErrors).length === 0) {
      const templateParams = {
        from_name: fullName,
        to_name: "8GIG Konstruct Team",
        body_message: messageInquiry,
        from_email: eMail,
        subject: subject,
        contact_number: contactNumber,
      };
      emailjs
        .send(
          "service_upi8jy6",
          "template_0gf64hj",
          templateParams,
          "oqCpa9JKoWaFWwK8R",
          setSendingStatus(true)
        )
        .then(
          function (response) {
            setErrorStatus({
              title: "Message Sent",
              message: "Nice! Talk to you soon!",
            });
            setMessageSent(true);
            console.log("SUCCESS!", response.status, response.text);
            setFormData({
              fullName: "",
              eMail: "",
              contactNumber: "",
              subject: "",
              messageInquiry: "",
            });
            setFormErrors({ "": "" });
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    }
  };

  return (
    <div className="contact-us__cont">
      <Modal isOpen={sendingStatus}>
        <lottie-player
          src="https://assets2.lottiefiles.com/packages/lf20_YSMTHj.json"
          background="transparent"
          speed="1"
          autoplay
          className="iframe--sending"
        />
        {messageSent ? (
          <button
            onClick={() => setSendingStatus(false)}
            className="contact-us__form__cta"
          >
            Okay
          </button>
        ) : null}
      </Modal>
      <form
        onSubmit={submitContactForm}
        ref={contactUsForm}
        className="contact-us__form__cont"
      >
        <div className="contact-us__form__items">
          <InputLabelForm
            onChange={onChangeInput}
            name={"fullName"}
            id={"full_name_text_box"}
            value={formData.fullName}
            label={"Full Name"}
            inputClassName={""}
            placeholder={"Enter your Full Name*"}
            required={true}
            formErrors={formErrors}
          />
          <InputLabelForm
            onChange={onChangeInput}
            name={"contactNumber"}
            id={"number_text_box"}
            value={formData.contactNumber}
            label={"Contact Number"}
            inputClassName={""}
            placeholder={"Enter your Contact Number*"}
            required={true}
            formErrors={formErrors}
          />
        </div>
        <div className="contact-us__form__items">
          <InputLabelForm
            onChange={onChangeInput}
            name={"eMail"}
            id={"email_text_box"}
            value={formData.eMail}
            label={"E-mail Address"}
            inputClassName={""}
            placeholder={"Enter your E-mail*"}
            required={true}
            type={"email"}
            formErrors={formErrors}
          />
        </div>
        <div className="contact-us__form__items">
          <InputLabelForm
            onChange={onChangeInput}
            name={"subject"}
            id={"subject_text_box"}
            value={formData.subject}
            label={"Subject"}
            inputClassName={""}
            placeholder={"Subject*"}
            required={true}
            formErrors={formErrors}
          />
        </div>
        <div className="contact-us__form__items">
          <InputLabelForm
            onChange={onChangeInput}
            name={"messageInquiry"}
            id={"message_text_box"}
            value={formData.messageInquiry}
            label={"Message"}
            inputClassName={"--message"}
            placeholder={"Your inquiry*"}
            required={true}
            element={"textarea"}
            formErrors={formErrors}
          />
        </div>
        <input
          type="submit"
          value="send"
          className="contact-us__form__submit"
          // disabled={true}
        />
      </form>
    </div>
  );
}

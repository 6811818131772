export const navbarMenuList = [
  {
    id: 1,
    title: "Home",
    path: "/",
    tooltip: "8gigki Home page",
  },
  {
    id: 2,
    title: "About us",
    path: "/about_us",
    tooltip: "8gigki About us page",
  },
  {
    id: 3,
    title: "Services",
    path: "/services",
    tooltip: "8gigki Services page",
  },
  {
    id: 4,
    title: "Projects",
    path: "/projects",
    tooltip: "8gigki Projects page",
  },
  {
    id: 5,
    title: "Contact us",
    path: "/contact_us",
    tooltip: "8gigki Contact us page",
  },
];

export const navbarMobileMenuList = [
  {
    id: 1,
    title: "Home",
    path: "/",
    tooltip: "8gigki Home page",
  },
  {
    id: 2,
    title: "About us",
    path: "/about_us",
    tooltip: "8gigki About us page",
  },
  {
    id: 3,
    title: "Our Team",
    path: "/our_team",
    tooltip: "8gigki  page",
  },
  {
    id: 4,
    title: "Our Clients",
    path: "/our_clients",
    tooltip: "8gigki Clients page",
  },
  {
    id: 5,
    title: "Services",
    path: "/services",
    tooltip: "8gigki Services page",
  },
  {
    id: 6,
    title: "Projects",
    path: "/projects",
    tooltip: "8gigki Projects page",
  },
  {
    id: 7,
    title: "Contact us",
    path: "/contact_us",
    tooltip: "8gigki Contact us page",
  },
];

export const navbarAboutList = [
  {
    id: 1,
    title: "Our Team",
    path: "/our_team",
    tooltip: "8gigki Team page",
  },
  {
    id: 2,
    title: "Our Clients",
    path: "/our_clients",
    tooltip: "8gigki Clients page",
  },
];

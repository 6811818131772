import React, { useEffect } from "react";
import "./serviceDetails.scss";
import { companyServicesData } from "../data/servicesFullList";

export default function ServiceDetails() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const service_type = urlParams.get("service_type");

  const getSomething = () => {
    const get_service = companyServicesData.desc.filter((service) => {
      if (service.title === service_type) {
        return service;
      }
    });

    const service_details = get_service.map((service) => {
      return service;
    });

    const service_details_items = service_details[0].details?.map(
      (details) => details
    );

    return (
      <div className="service__details__cont">
        <div className="service__details__header">
          <h1>{service_details[0]?.title}</h1>
        </div>
        <div className="service__details__details__cont">
          <div className="service__details__intro__cont">
            <p className="service__details__intro">
              {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Vero
              cupiditate, facilis quasi atque reiciendis ab in perferendis quis,
              nisi numquam rem. Autem, suscipit dicta quas et quam debitis eaque
              laboriosam. */}
            </p>
          </div>
          <div className="service__details__items__cont">
            <div className="service__details__items__para">
              {service_details_items.map((services) => {
                return (
                  <div
                    className="service__details__items__para__cont"
                    key={services.id}
                  >
                    <span>✓</span>
                    <p>{services.desc}</p>
                  </div>
                );
              })}
            </div>
            <div className="service__details__items__image">
              <img
                src={service_details[0].image}
                alt="8gigki services icon"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{getSomething()}</div>;
}

import React from "react";

export default function FooterSocialLinks() {
  const publicPath = window.location.origin;
  return (
    <div className="footer__social__cont">
      <div className="footer__social__header">
        <h3>Follow us</h3>
      </div>
      <div>
        <a
          href="https://www.facebook.com/8GIG-Konstruct-Inc-1695948724061862"
          target={"_blank"}
          className="footer__social__icon__cont"
          title="8gigki Facebook Page"
        >
          <img
            src={`${publicPath}/facebook.png`}
            alt="8gigki link facebook icon"
            loading="lazy"
          />
        </a>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./resultsList.scss";
import { clientsFullLists } from "../data/clientFullList";
import nophoto_proj from "../../assets/image/defaultnophoto.jpg";

export default function ResultsList(props) {
  const { dataClient, dataService, dropDownChange, page } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage, setProjectsPerPage] = useState(6);
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const [selectedClient, setSelectedClient] = useState();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const client_name = urlParams.get("client");
  const clientResult = dataClient ?? client_name;

  const projectRef = useRef(null);

  const scrollToView = () =>
    projectRef.current.scrollIntoView({ behavior: "smooth" });

  const getClientName = () => {
    let clients = clientsFullLists?.desc?.filter(function (item) {
      return item.title === clientResult;
    });

    let clients_project = clients.map((item) => {
      return item.projects.filter(function (projects) {
        if (dataService === "all") {
          return projects;
        } else {
          return projects.project_service_title === dataService;
        }
      });
    });
    const projectCount = clients_project[0]?.length;

    setSelectedClient(projectCount);
  };

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      getClientName();

      if (dropDownChange) {
        setCurrentPage(page);
        scrollToView();
      }
    }
  }, [dropDownChange]);

  const prevPageButton = () => {
    setCurrentPage(currentPage - 1);
    scrollToView();
  };
  const nextPageButton = () => {
    setCurrentPage(currentPage + 1);
    scrollToView();
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(selectedClient / projectsPerPage); i++) {
      pageNumbers.push(i);
    }
    return pageNumbers;
  };

  const renderPagination = () => {
    return (
      <div className="pagination__cont_">
        {currentPage != 1 && (
          <span
            className="arrow__page arrow__page--left"
            onClick={prevPageButton}
          >
            <span />
          </span>
        )}
        <span>
          page {currentPage} of {getPageNumbers().at(-1)}
        </span>
        {currentPage != getPageNumbers().at(-1) && getPageNumbers().length > 1 && (
          <span
            className="arrow__page arrow__page--right"
            onClick={nextPageButton}
          >
            <span />
          </span>
        )}
      </div>
    );
  };

  const getProjects = () => {
    let clients = clientsFullLists?.desc?.filter(function (item) {
      return item.title === clientResult;
    });

    let clients_project = clients.map((item) => {
      return item.projects.filter(function (projects) {
        if (dataService === "all" && projects.project_title) {
          return projects;
        } else {
          return projects.project_service_title === dataService;
        }
      });
    });

    let currentDisplayedProjects = clients_project[0]?.slice(
      indexOfFirstProject,
      indexOfLastProject
    );

    return (
      <div className="projects__results__result">
        {currentDisplayedProjects?.map((project) => {
          return (
            // <Link
            //   key={project.id}
            //   to={`project_details?client=${clientResult}&project_id=${project.id}&project=${project.project_title}`}
            // >
            <div className="projects__result__item" key={project.id}>
              <img
                src={project.project_image[0]?.image ?? nophoto_proj}
                alt="8gigki project photos"
                className="project__image__item"
                loading="lazy"
              />
              <div className="projects__description__tag">
                <span className="projects__results__service">
                  {project.project_service_type}
                </span>
                <h4 className="project__result__title">
                  {project.project_title}
                </h4>
              </div>
            </div>
            // </Link>
          );
        })}
      </div>
    );
  };

  return (
    <div className="project__results__cont" ref={projectRef}>
      <div className="projects__results">
        <h3 className="projects__results__header">{clientResult}</h3>
        {getProjects()}
      </div>
      {clientResult && renderPagination()}
    </div>
  );
}

import React from "react";
import "./footer.scss";
import FooterAddress from "./footerDetails/footerDetails";
import FooterNav from "./footerDetails/footerNav";
import CopyRight from "./footerDetails/copyRight";
import FooterSocialLinks from "./footerDetails/footerSocialLinks";
import CRIF_photo from "../../assets/image/accreds/CRIF.png";
import VIA_photo from "../../assets/image/accreds/VIA.png";

export default function Footer() {
  return (
    <div className="footer__cont">
      <div className="footer__content__cont">
        <FooterAddress />
        {/* <FooterNav /> */}
        <FooterSocialLinks />
        <div className="footer__accreditted__cont">
          <div className="footer__accreditted">
            <img src={CRIF_photo} alt="CRIF badge 8gigki" loading="lazy" />
          </div>
          <div className="footer__accreditted">
            <img
              src={VIA_photo}
              alt="VIA Certified badge 8gigki"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <CopyRight />
    </div>
  );
}

export const footerAddressDetails = [
  {
    id: 1,
    title: "address",
    desc: "4-A P. Rosales St., Sta. Ana Pateros, Metro Manila",
  },
];

export const mobileDetails = [
  { id: 1, title: "mobile1", desc: "(+632) 8579-2054 / 55" },
  { id: 2, title: "mobile2", desc: "(+632) 8290-1620" },
];

export const emailDetails = [
  { id: 2, title: "email2", desc: "8giges@gmail.com" },
];

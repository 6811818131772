import React from "react";

export default function MappingUtil(props) {
  const { data } = props;
  return (
    <div className="mapping__cont">
      {data.map((item) => {
        return <props.tag key={item.id}>{item.desc}</props.tag>;
      })}
    </div>
  );
}

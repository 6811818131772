import React, { useEffect } from "react";
import NavBar from "../navigationBar/NavBar";
import Footer from "../footer/footer";
import ImageSlider from "../slider/imageSlider";
import { serviceSliderImages } from "../data/sliderData";
import GalleryService from "../galleryService/galleryService";

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  

  return (
    <div>
      <NavBar />
      <ImageSlider slides={serviceSliderImages} />
      <GalleryService />
      <Footer />
    </div>
  );
}

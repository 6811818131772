import React, { useEffect } from "react";
import Footer from "../footer/footer";
import NavBar from "../navigationBar/NavBar";
import ImageSlider from "../slider/imageSlider";
import { projectImageSlider } from "../data/sliderData";
import ProjectsGallery from "../projectListings/projectsGallery";
import "./projects.scss";
import { usePageTracking } from "../utils/usePageTracking";

export default function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="projects__cont">
      <NavBar />
      <ImageSlider slides={projectImageSlider} />
      <div className="projects__header__cont">
        <h2>Projects</h2>
      </div>
      <div className="project__gallery__cont">
        <ProjectsGallery />
      </div>
      <Footer />
    </div>
  );
}

import React from "react";
import "./copyRight.scss";

export default function CopyRight() {
  return (
    <div className="copy-right__cont">
      <small>
        2022 ⓒ 8GIG KONSTRUCT, INC. <br /> ALL RIGHTS RESERVED.{" "}
      </small>
    </div>
  );
}
